var sApp = sApp || {};

sApp.TestResults = library(function() {
    return {
        init: function () {
            this.elements = {
                root: $('.js-test-result')
            };

            if(!this.elements.root.length) return false;

            this._render();
            this._bind();
        },
        _bind: function () {
            var _this = this,
                restartBtn = $('.js-restart-test');

            restartBtn.on('click', function (e) {
                e.preventDefault();

                _this.restartTest();
            });
        },
        _render: function () {
            var promoLinks = $('.promo-links').find('.promo-link'),
                promoLinksVis = $('.promo-links').find('.promo-link:visible'),
                services = shuffle(promoLinksVis).slice(0, 3);

            function shuffle(array) {
                var m = array.length, t, i;

                while (m) {
                    i = Math.floor(Math.random() * m--);

                    t = array[m];
                    array[m] = array[i];
                    array[i] = t;
                }

                return array;
            }

            //promoLinks.addClass('_hide');
            //services.removeClass('_hide').addClass('_show');
        },
        parseUrl: function () {
            var _this = this,
                origin =  window.location.origin,
                path = window.location.pathname,
                url;

            if(path.indexOf(".html") > -1 || path.indexOf(".php") > -1) {
                var parts = path.split("/"),
                    pathPart = parts.slice(0, -1).join('/');

                if (parts.length > 2){
                    url = origin + pathPart;
                } else {
                    url = origin;
                }
            } else {
                url = origin + path;
            }

            return url;
        },
        restartTest: function () {
            var url = this.parseUrl();

            window.location.replace(url);
        }
    };
}());