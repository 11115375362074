var sApp = sApp || {};

sApp.$W = $(window);
sApp.$D = $(document);

function library(module) {
    $(function() {
        if (module.init) {
            module.init();
        }
    });
    return module;
}

$(function() {

    $('.js-social-trigger').on('click', function() {
        $('.social-block__list').addClass('opened');
        $(this).hide();
    });

    $(document).mouseup(function (e) {
        if ($(window).width() < 769) {
            var socialBlock = $('.social-block__list');
            if (socialBlock.has(e.target).length === 0) {
                socialBlock.removeClass('opened');
                $('.js-social-trigger').show();
            }
        }
    });

    if ($('.main-page').length) {
        sendGa('main', 'load', 'main');
    }

    $('.ya-share2__item_service_vkontakte a').on('click', function () {
        sendGa('main', 'click', 'vk');
    });

    $('.ya-share2__item_service_facebook a').on('click', function () {
        sendGa('main', 'click', 'fb');
    });

    $('.ya-share2__item_service_twitter a').on('click', function () {
        sendGa('main', 'click', 'tw');
    });

    $('.ya-share2__item_service_odnoklassniki a').on('click', function () {
        sendGa('main', 'click', 'ok');
    });

    if ($('.scroll-down').length) {
        $(window).on('scroll', function () {
            if($(this).scrollTop() > 0) {
                $('.scroll-down').fadeOut();
            }
        });

        $(document).on('click', '.scroll-down', function (event) {
            event.preventDefault();

            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top
            }, 400);
        });
    }
});

function sendGa(category, action, label) {
    ga('send', {
        hitType: 'event',
        eventCategory: category,
        eventAction: action,
        eventLabel: label
    });
}