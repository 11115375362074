var sApp = sApp || {};

sApp.Test = library(function() {
    return {
        init: function () {
            this.elements = {
                root: $('.js-test')
            };

            this.points = {
                a: 0,
                k: 0,
                s: 0,
                l: 0
            };

            if(!this.elements.root.length) return false;

            this._render();
            this._bind();
        },
        _bind: function () {
            var _this = this,
                startBtn = $('.js-start'),
                radioBtn = $('.js-answers').find('input[type="radio"]');

            startBtn.on('click', function (e) {
                var introScreen = $('.intro._main'),
                    testScreen = $('.test');

                e.preventDefault();

                introScreen.fadeOut(function () {
                    testScreen.addClass('_opened');
                });
            });

            radioBtn.on('change', function () {
                _this.calcPoints($(this));
            });
        },
        _render: function () {

        },
        startTest: function (e) {
            var introBlock = $('.js-map-intro'),
                map = $('.js-test');

            e.preventDefault();

            introBlock.fadeOut(300, function () {
                map.fadeIn(300);
            });
        },
        calcPoints: function (elem) {
            var _this = this,
                current = elem.parents('.js-question.active'),
                type = elem.data('type');

            this.points[type] += 1;

            this.nextQuestion(current);
        },
        nextQuestion: function (current) {
            var next = current.next();

            if (next.length) {
                current.removeClass('active');
                setTimeout(function () {
                    next.addClass('active');
                }, 300);
                this.changeCounter();

                sendGa('quiz', 'load', 'question - '+next.index());
            } else {
                this.calcResultPoints();
            }
        },
        changeCounter: function () {
            var _this = this,
                indicator = $('.js-indicator'),
                left = parseFloat(indicator.css('left')),
                nextPos;

            if ($(window).width() > 639) {
                nextPos = left + 36;
                indicator.css('left', nextPos+'px');
            } else {
                nextPos = 'calc('+ left + 'px +' + ' 25%)';
                indicator.css({
                    'left': '-webkit-'+nextPos,
                    'left': nextPos
                });
            }
        },
        calcResultPoints: function () {
            var _this = this,
                arr,
                max,
                maxEntr,
                obj,
                dupe = false,
                page;

            obj = Object.keys(_this.points);
            arr = obj.map(function (key) {
                return _this.points[key];
            });

            max = Math.max.apply( null, arr );
            page = getKeyByValue(max, _this.points);

            for(var i = 0; i < obj.length; i++){
                for(var j = i + 1; j < obj.length; j++){
                    if(_this.points[obj[i]] === _this.points[obj[j]] && _this.points[obj[i]] === max){
                        dupe = true;
                        break;
                    }
                }
            }

            _this.showTestResult(dupe, page);

            function getKeyByValue( value, obj ) {
                for( var prop in obj ) {
                    if( obj.hasOwnProperty( prop ) ) {
                        if( obj[ prop ] === value )
                            return prop;
                    }
                }
            }
        },
        parseUrl: function () {
            var _this = this,
                origin =  window.location.origin,
                path = window.location.pathname,
                url;

            if(path.indexOf(".html") > -1 || path.indexOf(".php") > -1) {
                var parts = path.split("/"),
                    pathPart = parts.slice(0, -1).join('/');

                if (parts.length > 2){
                    url = origin + pathPart;
                } else {
                    url = origin;
                }
            } else {
                url = origin + path;
            }

            return url;
        },
        showTestResult: function (dupe, page) {
            var url = this.parseUrl();

            if (dupe) {
                sendGa('quiz', 'load', 'result - d');
                window.location.replace(url + 'result-d.html');
            } else {
                sendGa('quiz', 'load', 'result - '+page);
                window.location.replace(url + 'result-'+page+'.html');
            }
        }
    };
}());